const AllChargeStationTableHeadings = {
  heading: 'List Of Stations',
  operatorname: 'Operatorname',
  stationame: 'Station Name',
  chargebox: 'ChargeBox Id',
  type: 'Types',
  connectors: 'Connectors',
  port_type: 'Port Type',
  location: 'Location',
  status: 'Status',
}

const StationOperatorHeadings = {
  heading: ' OPERATOR DETAILS',
  username: 'User Name',
  email: 'Email',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  addressline: 'Address Line 1',
  amcstartdate: 'AMC Start Date',
  amcenddate: 'AMC End Date',
}

const TariffTableHeadings = {
  heading: 'Traiff Management',
  subheading: 'Over 5 New Profiles',
  tariffname: 'Tariff Name',
  currency: 'Currency',
  type: 'Type',
  operatorname: 'Operator Name',
}

const scheduleTableHeadings = {
  heading: 'SCHEDULE REPORT',
  subheading: 'Over 10 SCHEDULE REPORT',
  id: 'ID',
  reportname: 'Report Name',
  operatorname: 'Operator Name',
  operatorid: 'Operator Id',
  reportinterval: 'Report Interval',
  startdate: 'Start Date',
  endate: 'End Date',
  nextexecutiondate: 'Next Exceution Date',
  torecipient: 'To Recipient',
  ccrecipient: 'Cc Recipient',
}

const evUserTableHeadings = {
  heading: 'Users List',
  subheading: 'Over 20 Users',
  username: 'Username',
  userid: 'User Id',
  email: 'Email',
  mobilenumber: 'Mobile Number',
  platform: 'Platform',
  // tenantid: 'Tenant Id',
  date: 'Created Date',
  rfid: 'Rfid Tag',
  progress: 'Progress',
}

const AssignedTable = {
  heading: 'Assigned Rfid Tags',
  subheading: 'Over 20 Transactions',
  rfidTag: 'RFID Tag',
  userdetails: 'User Details',
  usermobile: 'User Mobile',
  createdby: 'Created By',
  status: 'Status',
  mastertag: 'Master Tag',
}

const OperatorInformationHeading = {
  heading: ' OPERATOR DETAILS',
  username: 'User Name',
  email: 'Email',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  addressline: 'Address Line 1',
  amcstartdate: 'AMC Start Date',
  amcenddate: 'AMC End Date',
}

const GroupTableHeadings = {
  heading: 'Groups',
  subheading: 'Over 10 Groups',
  groupname: 'Group Name',
  createdby: 'Created By',
}

const cmsTableHeadings = {
  heading: 'CMS Users',
  subheading: 'Over 10 Users',
  username: 'UserName',
  email: 'Email Id',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  group: 'Group Type',
}

const stationDetailsHeadings = {
  heading: 'Charging Station Details',
  subheading: 'Connector Details',
  stationame: 'Station Name',
  stationid: 'Station Id',
  operatorid: 'Operator Id',
  vendorname: 'Vendor Name',
  firmwareversion: 'Firmware Version',
  model: 'Model',
  serial: 'Serial',
  status: 'Station Status',
  address: 'Address',
  connectors: 'Number Of Connectors',
  multiconnectors: 'Multi Connectors',
  rating: 'Voltage Rating (V)',
  medium: 'Communication Medium',
  connectorid: 'Connector Id',
  port: 'Port Type',
  connectorstatus: 'Status',
  power: 'Power Output',
  tariff: 'Tariff Name',
  charge: 'Charge Type',
}
export {
  AllChargeStationTableHeadings,
  StationOperatorHeadings,
  TariffTableHeadings,
  scheduleTableHeadings,
  evUserTableHeadings,
  AssignedTable,
  OperatorInformationHeading,
  GroupTableHeadings,
  cmsTableHeadings,
  stationDetailsHeadings,
}
