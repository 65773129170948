import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Home from '../pages/home/Home'
import Chargepoint from '../pages/Chargepoint'
import Servicerequest from '../pages/Servicerequest'
import OperatorInformation from '../pages/OperatorInformation'
import TabsSlide from '../pages/Components/Tabs'
import StationDetails from '../pages/StationDetails'
import StationDetailsHeader from '../pages/Components/StationDetailsHeader'
import TariffDetails from '../pages/Components/TariffDetails'
import TariffDetailsHeader from '../pages/Components/TariffDetailsHeader'
import UserSinglePage from '../pages/UserSinglePage'
import UserDetailsHeader from '../pages/Components/UserDetailsHeader'
import WalletSinglePage from '../pages/WalletSinglePage'
import WalletDetailsHeader from '../pages/Components/WalletDetailsHeader'
import TransactionSinglePage from '../pages/TransactionSinglePage'
import DetailsTransaction from '../pages/Components/DetailsTransaction'
import PendingTransaction from '../pages/PendingTransaction'
import PTransactions from '../pages/Components/PTransactions'
import FailedT from '../pages/FailedT'
import FailedDetails from '../pages/Components/FailedDetails'
import RfidSinglePage from '../pages/RfidSinglePage'
import RfidUsers from '../pages/Components/RfidUsers'
import RfidUserSinglePage from '../pages/RfidUserSinglePage'
import RfidUserD from '../pages/Components/RfidUserD'
import ConsolidateSinglePage from '../pages/ConsolidateSinglePage'
import ConsolidateData from '../pages/Components/ConsolidateData'
import ConsolidateCreated from '../pages/ConsolidateCreated'
import ConsolidateCreate from '../pages/Components/ConsolidateCreate'
import RolesSinglePage from '../pages/RolesSinglePage'
import RolesSingleData from '../pages/Components/RolesSingleData'
import GroupSinglePage from '../pages/GroupSinglePage'
import GroupSingleData from '../pages/Components/GroupSingleData'
import ServiceReqSingle from '../pages/ServiceReqSingle'
import ServiceReqD from '../pages/Components/ServiceReqD'
import AddChargeStation from '../pages/AddChargeStation'
import AddStationDetails from '../pages/Components/AddStationDetails'
import AddNewTariff from '../pages/AddNewTariff'
import AddTraiff from '../pages/Components/AddTraiff'
import AddNewUser from '../pages/AddNewUser'
import AddUser from '../pages/Components/AddUser'
import AddTag from '../pages/AddTag'
import AddTags from '../pages/Components/AddTags'
import AddConsolidate from '../pages/AddConsolidate'
import AddConsolidates from '../pages/Components/AddConsolidates'
import AddSchedule from '../pages/AddSchedule'
import AddSchedules from '../pages/Components/AddSchedules'
import AddCmsUser from '../pages/AddCmsUser'
import AddCms from '../pages/Components/AddCms'
import CMSsinglepage from '../pages/CMSsinglepage'
import CMSDATA from '../pages/Components/CMSDATA'
import EditTariff from '../pages/Components/EditTraiff'
import ChargePointEdit from '../pages/ChargePointEdit'
import EditStationDetails from '../pages/Components/EditStationDetails'
import WalletEditPage from '../pages/WalletEditPage'
import RfidEdit from '../pages/RfidEdit'
import ConsolidateEdited from '../pages/ConsolidateEdited'
import EditCMS from '../pages/EditCMS'
import EditSchedule from '../pages/EditSchedule'
import GroupEditPage from '../pages/GroupEditPage'
import StationOperatorDetails from '../pages/StationOperatorDetails'
import EditUserDetails from '../pages/EditUserDetails'
import Employees from '../pages/Employees'
import AddEmployee from '../pages/AddEmployee'
import EditEmployee from '../pages/EditEmployee'
import EmployeeDetails from '../pages/Components/EmployeeDetails'
import Amenities from './../../_metronic/partials/widgets/tables/Amenities'
import Dashboard from '../modules/analytics/menu/components/Dashboard'
import {TotalDeviceTransaction} from '../../_metronic/partials/widgets/tables/TotalDeviceTransaction'
import {TotalConnectors} from '../../_metronic/partials/widgets/tables/TotalConnectors'

const PrivateRoutes = () => {
  const AnalyticsPage = lazy(() => import('../modules/analytics/menu/AnalyticsPage'))
  const ChargestationPage = lazy(() => import('../modules/Chargestations/Charge/ChargestationPage'))
  const EVUsersPage = lazy(() => import('../modules/Evusers/EV/EVUsersPage'))
  const UserManagementPage = lazy(() => import('../modules/UserManagment/User/UserManagementPage'))
  const CommununicationPage = lazy(() => import('../modules/Communication/Task/CommunicationPage'))
  const ReportPage = lazy(() => import('../modules/report/data/ReportPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const FinancialPage = lazy(() => import('../modules/FinancialManagement/FinancialPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/map' element={<Home />} />
        <Route path='/Chargepoint' element={<Chargepoint />} />
        <Route path='/ChargePointEdit/:pointId' element={<ChargePointEdit />} />
        <Route path='/EditChargepoint/:pointId' element={<Chargepoint />} />
        <Route path='/OperatorInformation' element={<OperatorInformation />} />
        <Route path='/OperatorInformation/:pointId' element={<TabsSlide />} />
        <Route path='/StationOperatorDetails/:pointId' element={<StationOperatorDetails />} />
        <Route path='/AddChargeStation' element={<AddChargeStation />} />
        <Route path='/AddChargeStation/:pointId' element={<AddStationDetails />} />
        <Route path='/EditChargeStation/:pointId' element={<EditStationDetails />} />
        <Route path='/AddNewTariff' element={<AddNewTariff />} />
        <Route path='/AddNewTariff/:pointId' element={<AddTraiff />} />
        <Route path='/AddNewUser' element={<AddNewUser />} />
        <Route path='/AddNewUser/:pointId' element={<AddUser />} />
        <Route path='/AddTag' element={<AddTag />} />
        <Route path='/AddTag/:pointId' element={<AddTags />} />
        <Route path='/AddConsolidate' element={<AddConsolidate />} />
        <Route path='/AddConsolidate/:pointId' element={<AddConsolidates />} />
        <Route path='/AddSchedule' element={<AddSchedule />} />
        <Route path='/AddSchedule/:pointId' element={<AddSchedules />} />
        <Route path='/AddCmsUser' element={<AddCmsUser />} />
        <Route path='/AddCmsUser/:pointId' element={<AddCms />} />
        <Route path='/CMSsinglepage' element={<CMSsinglepage />} />
        <Route path='/CMSsinglepage/:pointId' element={<CMSDATA />} />
        <Route path='/StationDetails' element={<StationDetails />} />
        <Route path='/StationDetails/:pointId' element={<StationDetailsHeader />} />
        <Route path='/TariffSinglePage' element={<TariffDetails />} />
        <Route path='/TariffSinglePage/:pointId' element={<TariffDetailsHeader />} />
        <Route path='/TariffEditPage/:pointId' element={<EditTariff />} />
        <Route path='/UserSinglePage' element={<UserSinglePage />} />
        <Route path='/UserSinglePage/:pointId' element={<UserDetailsHeader />} />
        <Route path='/EditUserDetails/:pointId' element={<EditUserDetails />} />
        <Route path='/WalletSinglePage' element={<WalletSinglePage />} />
        <Route path='/WalletSinglePage/:pointId' element={<WalletDetailsHeader />} />
        <Route path='/WalletEditPage/:pointId' element={<WalletEditPage />} />
        <Route path='/TransactionSinglePage' element={<TransactionSinglePage />} />
        <Route path='/TransactionSinglePage/:pointId' element={<DetailsTransaction />} />
        <Route path='/PendingTransaction' element={<PendingTransaction />} />
        <Route path='/PendingTransaction/:pointId' element={<PTransactions />} />
        <Route path='/FailedT' element={<FailedT />} />
        <Route path='/FailedT/:pointId' element={<FailedDetails />} />
        <Route path='/RfidSinglePage' element={<RfidSinglePage />} />
        <Route path='/RfidSinglePage/:pointId' element={<RfidUsers />} />
        <Route path='/RfidUserSinglePage' element={<RfidUserSinglePage />} />
        <Route path='/RfidUserSinglePage/:pointId' element={<RfidUserD />} />
        <Route path='/RfidEdit/:pointId' element={<RfidEdit />} />
        <Route path='/ConsolidateSinglePage' element={<ConsolidateSinglePage />} />
        <Route path='/ConsolidateSinglePage/:pointId' element={<ConsolidateData />} />
        <Route path='/ConsolidateCreated' element={<ConsolidateCreated />} />
        <Route path='/ConsolidateCreated/:pointId' element={<ConsolidateCreate />} />
        <Route path='/ConsolidateEdited/:pointId' element={<ConsolidateEdited />} />
        <Route path='/EditCMS/:pointId' element={<EditCMS />} />
        <Route path='/RolesSinglePage' element={<RolesSinglePage />} />
        <Route path='/RolesSinglePage/:pointId' element={<RolesSingleData />} />
        <Route path='/GroupSinglePage' element={<GroupSinglePage />} />
        <Route path='/GroupSinglePage/:pointId' element={<GroupSingleData />} />
        <Route path='/GroupEditPage/:pointId' element={<GroupEditPage />} />
        <Route path='/EditSchedule/:pointId' element={<EditSchedule />} />
        <Route path='/Servicerequest' element={<Servicerequest />} />
        <Route path='/ServiceReqSingle' element={<ServiceReqSingle />} />
        <Route path='/ServiceReqSingle/:pointId' element={<ServiceReqD />} />
        <Route path='/Employees' element={<Employees />} />
        <Route path='/AddEmployee' element={<AddEmployee />} />
        <Route path='/EditEmployee/:pointId' element={<EditEmployee />} />
        <Route path='/Employee/:pointId' element={<EmployeeDetails />} />
        <Route path='/amenity/amenities' element={<Amenities />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/transactions' element={<TotalDeviceTransaction />} />
        <Route path='/total-connectors' element={<TotalConnectors />} />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='FinancialManagement/*'
          element={
            <SuspensedView>
              <FinancialPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/menu/*'
          element={
            <SuspensedView>
              <AnalyticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='ChargeStations/Charge/*'
          element={
            <SuspensedView>
              <ChargestationPage />
            </SuspensedView>
          }
        />
        <Route
          path='Evusers/EV/*'
          element={
            <SuspensedView>
              <EVUsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='report/data/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='UserManagment/User/*'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='Communication/Task/*'
          element={
            <SuspensedView>
              <CommununicationPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
