/* eslint-disable */

import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {stationDetailsHeadings} from '../Helpers/TableHeadings'
import {getRequest} from '../Helpers/Requests'
import toast from 'react-hot-toast'
import StartChargeModal from '../../UI/StartChargeModal'
import {setTimeout} from 'timers'
import axios from 'axios'

const DeviceSpecification = () => {
  let {pointId} = useParams()
  const [DeviceSpectifications, setDeviceSpectifications] = useState<any>(null)
  const [specification, setSpecification] = useState<any>()
  const [active, setActive] = useState(false)

  const getStationById = async () => {
    try {
      const res = await getRequest(`/stations/${pointId}`)
      if (res && res.data) {
        console.log(res.data.data.station, 'data coming here')
        setDeviceSpectifications(res.data.data.station)
      }
    } catch (error: any) {
      console.log(error.messsage)
    }
  }

  const handleSubmit = () => {
    try {
      toast.success('Successfully Started the Charger')
    } catch (error) {
      console.log(error)
    }
  }
  const stopCharger = async () => {
    try {
      const res = await axios.post(
        'http://localhost:3000/ocpp/remote/stop-transaction/' + DeviceSpectifications?.stationId,
        {
          trasactionId: '8B9765DC1B8D',
        }
      )
      if (res.status === 200) {
        toast.success('Successfully Stopped the Charger')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDeviceSpecification = async () => {
    try {
      const res = await getRequest('/device-specification/' + DeviceSpectifications.stationId)
      if (res?.status === 200) {
        setSpecification(res.data.data)
        console.log(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getStationById()
  }, [])

  useEffect(() => {
    if (
      DeviceSpectifications &&
      DeviceSpectifications.stationId !== null &&
      DeviceSpectifications.stationId !== undefined &&
      DeviceSpectifications.stationId !== ''
    ) {
      getDeviceSpecification()
    }
  }, [DeviceSpectifications])

  return (
    <div>
      {/* ChargeStation:Details:: Starts */}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body p-9'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    DeviceSpectifications?.image
                      ? DeviceSpectifications?.image
                      : '/media/avatars/blank.png'
                  }
                  alt='image'
                />
                <div
                  className={`position-absolute translate-middle bottom-0 start-100 mb-6 ${
                    DeviceSpectifications?.status === 'Active' ? 'bg-success' : 'bg-danger'
                  } rounded-circle border border-4 border-body h-20px w-20px`}
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {DeviceSpectifications?.stationName}
                    </a>
                    <a href='#'>
                      <i className='ki-outline ki-verify fs-1 text-primary' />
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                    >
                      <i className='ki-outline ki-profile-circle fs-4 me-1' />
                      {DeviceSpectifications?.operator.OperatorName}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                    >
                      <i className='ki-outline ki-geolocation fs-4 me-1' />{' '}
                      {DeviceSpectifications?.location?.city}
                    </a>
                    {/* <a
                          href='#'
                          className='d-flex align-items-center text-gray-500 text-hover-primary mb-2'
                        >
                          <i className='ki-outline ki-sms fs-4 me-1' /> "alksjf"
                        </a> */}
                  </div>
                </div>
                <div className='d-flex my-4'>
                  {DeviceSpectifications?.status === 'Active' ? (
                    <a href='#' className={`btn btn-sm btn-success me-3`}>
                      Active
                    </a>
                  ) : (
                    <a href='#' className={`btn btn-sm btn-danger me-3`}>
                      Inactive
                    </a>
                  )}
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-down fs-3 text-danger me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={80}
                          data-kt-initialized={1}
                        >
                          {DeviceSpectifications?.charge_type}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Charger Type</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={60}
                          data-kt-countup-prefix='%'
                          data-kt-initialized={1}
                        >
                          {DeviceSpectifications?.voltage_rating}v
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Voltage</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={60}
                          data-kt-countup-prefix='%'
                          data-kt-initialized={1}
                        >
                          {DeviceSpectifications?.connectors.length}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>No.of Guns</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={60}
                          data-kt-countup-prefix='%'
                          data-kt-initialized={1}
                        >
                          {specification?.model}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Model</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={60}
                          data-kt-countup-prefix='%'
                          data-kt-initialized={1}
                        >
                          {specification?.firmwareVersion}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Firmware Version</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={60}
                          data-kt-countup-prefix='%'
                          data-kt-initialized={1}
                        >
                          {specification?.vendorName}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Vendor Name</div>
                    </div>
                    {/* {DeviceSpectifications?.status == 'Active' && (
                      <div
                        className={`border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ${
                          active ? 'bg-danger' : 'bg-success'
                        } cursor-pointer`}
                      >
                        <div className='fs-1 fw-bold counted'>
                          {active ? (
                            <span
                              onClick={() => {
                                setActive(!active)
                                stopCharger()
                              }}
                            >
                              Stop
                            </span>
                          ) : (
                            <span data-bs-toggle='modal' data-bs-target='#startCharge'>
                              Start
                            </span>
                          )}
                        </div>
                        <div className='fw-semibold fs-6 text-black'>Charge</div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        <h1>Connector Details</h1>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-4 rounded-1'>
          <thead>
            <tr className='text-start text-gray-500 bg-secondary-subtle fw-bold fs-7 text-uppercase gs-0'>
              <th className='min-w-210px'>{stationDetailsHeadings.connectorid}</th>
              <th className='min-w-210px'>{stationDetailsHeadings.port}</th>
              <th className='min-w-210px'>{stationDetailsHeadings.power}</th>
              <th className='min-w-210px'>{stationDetailsHeadings.tariff}</th>
              <th className='min-w-210px'>{stationDetailsHeadings.charge}</th>
              <th className='min-w-120px'>Status</th>
            </tr>
          </thead>
          <tbody>
            {DeviceSpectifications?.connectors.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <a
                      href='/metronic8/demo25/apps/ecommerce/catalog/edit-product.html'
                      className='text-gray-900 text-hover-primary'
                    >
                      {item.connector_ID}
                    </a>
                  </td>
                  <td className='text-start'>{item.port_type} </td>
                  <td className='text-start'>{item.power_output}</td>
                  <td className='text-start dt-type-numeric'>{item.Tariff.name} </td>
                  <td className='text-start dt-type-numeric'>
                    {DeviceSpectifications?.charge_type}
                  </td>
                  {item.status === 'Available' ? (
                    <td className=''>
                      <span className='badge text-bg-success text-white'>Available</span>
                    </td>
                  ) : item.status === 'Unavailable' ? (
                    <td>
                      <span className='badge text-bg-danger text-white'>Unavailable</span>
                    </td>
                  ) : (
                    <td className='text-start dt-type-numeric'>
                      <span className='badge text-bg-warning text-white'>
                        {item?.status === 'PowerFailure' ? 'Power Outage' : item?.status}
                      </span>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* <StartChargeModal
        submit={handleSubmit}
        setState={setActive}
        state={active}
        stationId={DeviceSpectifications?.stationId}
      /> */}
    </div>
  )
}

export default DeviceSpecification
